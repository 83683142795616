<template>

  <div class="container">

    <div class="row">

      <div class="col">
        <div id="sort-bar" class="row gy-2 gx-3 align-items-center mt-4">
          <div class="col-auto">
            <label class="visually-hidden" for="sortBy">Sort By</label>
            <select class="form-select" id="sortBy" name="sortBy" v-model="sortBy">
              <option value="title">Release Title</option>
              <option value="artist">Artist Name</option>
            </select>
          </div>
          <div class="col-auto">
            <button v-on:click="ascending = !ascending" class="sort-button btn btn-primary">
              <i v-if="ascending" class="bi bi-arrow-up"></i>
              <i v-else class="bi bi-arrow-down"></i>
            </button>
          </div>
          <div class="col-auto">
            <label class="visually-hidden" for="autoSizingInputGroup">Keyword Search</label>
            <div class="input-group">
              <input type="text" class="form-control" v-model="searchValue" placeholder="Search Records" id="search-input" />
            </div>
          </div>
          <div class="col-auto" v-if="totalRecords">
            <div class="col-auto gy-2 gx-3 align-items-right mt-3">Total Records:{{filteredRecipes.length}}/{{totalRecords}}</div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-12" v-for="(record, id, index) in filteredRecipes" :key="index">
        <div class="card mb-2 mt-4">
          <div class="row g-0">
            <div class="col-4">
              <img v-bind:src="record.thumb" class="img-fluid rounded-start" :alt="record.title" />
            </div>
            <div class="col-8">
              <div class="card-body">

                <div class="row">
                  <div class="col-12">
                    <p class="card-title">
                      <router-link :to="{ path: '/r/' + record.firestoreid }">{{record.title}} - <small>{{ record.artists_sort }}</small></router-link>
                    </p>
                    <p v-if="record.released_formatted"><small>Released: {{ record.released_formatted }}</small>
                      <span v-for="(genre, index) in record.genres" :key="index">
                        &nbsp;<span class="badge rounded-pill bg-info text-dark">{{ genre }}</span>
                      </span>
                    </p>
                    <p><small>Label: {{ record.labels[0].name }} - {{ record.labels[0].catno }}</small></p>
                  </div>
                  <!-- <div class="col-6">
                    <ul class="list-group">
                      <span v-for="(track, id, index) in record.tracklist" :key="index">
                        <li :class="[ track.type_ == 'heading' ? 'list-group-item list-group-item-secondary' : 'list-group-item list-group-item-light']">
                          <small>
                            <span v-if="track.type_ == 'heading'">{{track.title}}</span>
                            <span v-else>{{track.position}} - {{track.title}}</span>
                          </small>
                        </li>
                      </span>
                    </ul>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import firebase from 'firebase'
import { useLoadRecords } from '@/firebase'

export default {
  data () {
    return {
      ascending: true,
      sortBy: 'title',
      searchValue:'',
      records: null,
      filteredTotal: null
    }
  },
  created() {
    this.records = useLoadRecords()
    this.searchValue = this.$route.params.query_string
  },
  computed: {
    isLoggedIn() {
      let currentUser = firebase.auth().currentUser
      return ( !currentUser ) ? false : true
    },
    totalRecords() {
      return this.records.length
    },
    filteredRecipes() {
      let tempRecipes = this.records
      if (this.searchValue != '' && this.searchValue) {
        tempRecipes = tempRecipes.filter((item => {
          return item.searchText
            .toUpperCase()
            .includes(this.searchValue.toUpperCase())
        }))
      }

      tempRecipes = tempRecipes.sort((a, b) => {
        if (this.sortBy == 'title') {
          let fa = a.title.toLowerCase(), fb = b.title.toLowerCase()
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        } else if (this.sortBy == 'artist') {
          let fa = a.artists[0].name.toLowerCase(), fb = b.artists[0].name.toLowerCase()
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        }
      })

      if (!this.ascending) {
        tempRecipes.reverse()
      }

      return tempRecipes
    }
  }
}
</script>