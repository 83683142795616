<template>
  <div class="post">
    <div v-if="loading" class="loading">
      Loading...
    </div>

    <div v-if="error" class="error">
      {{ error }}
    </div>

    <div v-if="record" class="content">

      <!-- <div class="card mb-3 mt-4">
        <div class="row g-0">
          <div class="col-md-4">
            <img v-bind:src="record.images[0].uri" class="img-fluid rounded-start" :alt="record.title" />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="card mt-4">
        <table class="table m-0">
          <tr>
            <td>
              <img v-bind:src="record.thumb" />


              <span v-if="isLoggedIn">
                <br />
                <br />
                <button type="button" class="btn btn-success mt-3">Fetch Update Record</button>
              </span>
            </td>
            <td>
              <h2>{{ record.title }}</h2>
              <p>{{ record.artists_sort }}</p>
              <p v-if="record.released_formatted"><small>Released: {{ record.released_formatted }}</small></p>
              <p><small>Label: {{ record.labels[0].name }} - {{ record.labels[0].catno }}</small></p>
              <span v-for="(genre, index) in record.genres" :key="index">
                <span class="badge rounded-pill bg-info text-dark">{{ genre }}</span>&nbsp;
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <ul class="list-group">
                      <span v-for="(track, id, index) in record.tracklist" :key="index">
                        <li :class="[ track.type_ == 'heading' ? 'list-group-item list-group-item-secondary' : 'list-group-item list-group-item-light']">
                          <small>
                            <span v-if="track.type_ == 'heading'">{{track.title}}</span>
                            <span v-else>{{track.position}} - {{track.title}}</span>
                          </small>
                        </li>
                      </span>
                    </ul>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import { getRecord } from '@/firebase'

export default {
  data () {
    return {
      loading: false,
      record: null,
      error: null
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  computed: {
    isLoggedIn() {
      let currentUser = firebase.auth().currentUser
      return ( !currentUser ) ? false : true
    }
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.error = this.post = null
      this.loading = true
      this.record = getRecord(this.$route.params.id).then((response) => {
        console.log(response)
        this.record = response
        this.loading = false
        return response
      })
    }
  }
}
</script>
