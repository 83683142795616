export async function findReleaseMatch(discogsURL) {
let thisURL = discogsURL
let subStr = thisURL.replace( "https://www.discogs.com/release/", "" )
let releaseId = subStr.split('-')[ 0 ]
const response = await fetch('https://api.discogs.com/releases/' + releaseId + '?token=OUnAceLMFUUQMMOwuapYytlnIJgNWDcJxfRnjgir', { method: 'GET' } )
    return await response.json()
}

export async function findReleaseMatchById(releaseId) {
    const response = await fetch('https://api.discogs.com/releases/' + releaseId + '?token=OUnAceLMFUUQMMOwuapYytlnIJgNWDcJxfRnjgir', { method: 'GET' } )
        return await response.json()
}

export async function addReleaseToCollection(releaseId) {
    fetch('https://api.discogs.com/users/coldfumonkeh/collection/folders/1/releases/' + releaseId + '?token=OUnAceLMFUUQMMOwuapYytlnIJgNWDcJxfRnjgir', { method: 'POST', mode: 'no-cors' } )
}


export async function barcodeSearch(barcodeString) {
    const response = await fetch('https://api.discogs.com/database/search?q=' + barcodeString + '&type=barcode&token=OUnAceLMFUUQMMOwuapYytlnIJgNWDcJxfRnjgir', { method: 'GET' } )
    return await response.json()
}