import firebase from 'firebase'
import { ref, onUnmounted } from 'vue'

const config = {
  // Firebase config here
  apiKey: "AIzaSyADzXrddCWmzA5HiLY2hlI5kKl9gubHMJc",
  authDomain: "vinyl-countdown-e8c4e.firebaseapp.com",
  projectId: "vinyl-countdown-e8c4e",
  storageBucket: "vinyl-countdown-e8c4e.appspot.com",
  messagingSenderId: "942567153575",
  appId: "1:942567153575:web:5f25dabf97ea2487526082",
  measurementId: "G-69N35V68FK"
}

const firebaseApp = firebase.initializeApp(config)

const db = firebaseApp.firestore()
const recordsCollection = db.collection('records')

export const createRecord = record => {
  return recordsCollection.add(record)
}

export const getRecord = async id => {
  const record = await recordsCollection.doc(id).get()
  return record.exists ? record.data() : null
}

export const updateRecord = (id, record) => {
  return recordsCollection.doc(id).update(record)
}

export const deleteRecord = id => {
  return recordsCollection.doc(id).delete()
}

export const currentUser = () => {
  return firebase.auth().currentUser
}

// import { addReleaseToCollection } from '@/services/DiscogsService'

// export const mattTest = (records) => {
//   for(var i = 0; i < records.length; i++){
//     addReleaseToCollection(records[i].id)
//   }
// }

export const useLoadRecords = () => {
  const records = ref([])
  const close = recordsCollection.onSnapshot(snapshot => {
    records.value = snapshot.docs.map(doc => ({ firestoreid: doc.id, searchText: doc.data().title + '' + doc.data().artists_sort, ...doc.data() }))
  })
  onUnmounted(close)
  return records
}