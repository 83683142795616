import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/Login'
// import Register from '@/components/Register'
import Dashboard from '@/components/Dashboard.vue'
import RecordDetail from '@/components/RecordDetail.vue'
import BarcodeScanner from '@/components/BarcodeScanner.vue'

const routes = [
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: Register
  // },
  {
    path: '/scanner',
    name: 'scanner',
    component: BarcodeScanner
  },
  {
    path: '/r/:id',
    name: 'recorddetail',
    component: RecordDetail,
    props: route => ({ recordId: route.params.id })
  },
  {
    path: '/:query_string?',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/about',
    name: 'About',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
