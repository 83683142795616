<template>
  <div class="card card-body mt-4">
    <form @submit.prevent="onSubmit">
      <div class="form-group">
        <label>Barcode</label>
        <input v-model="form.barcode" class="form-control" required />
      </div>
      <button type="submit" class="btn btn-success mt-3">
        Find Barcode
      </button>
    </form>
    <br /><br />
    <StreamBarcodeReader
      @decode="(a, b, c) => onDecode(a, b, c)"
      @loaded="() => onLoaded()"
    ></StreamBarcodeReader>
  </div>
</template>

<script>
import firebase from 'firebase'
import { createRecord } from '@/firebase'
import { reactive } from 'vue'
import { StreamBarcodeReader } from "vue-barcode-reader"
import { barcodeSearch, findReleaseMatchById, addReleaseToCollection } from '@/services/DiscogsService'

const authListener = firebase.auth().onAuthStateChanged(function(user) {
  console.log(user, 'user')
  if (user){
    this.isAuthed = true;
  }
})

export default {
  components: {
    StreamBarcodeReader
  },
  setup() {
    authListener()
    const form = reactive({ barcode: '' })
    const onSubmit = async () => {
      let response = await barcodeSearch( form.barcode )
      if( response.results.length ){
        let singleResponse = await findReleaseMatchById( response.results[ 0 ].id )
        console.log(singleResponse)
        await createRecord({ ...singleResponse })
        await addReleaseToCollection(singleResponse.id)
      }
      form.barcode = ''
    }
    return { form, onSubmit }
  },
  data() {
    return {
      text: '',
      id: null,
      isAuthed: false
    };
  },
  methods: {
    onDecode(barcodeValue) {
      this.text = barcodeValue
      this.form.barcode = barcodeValue
      if (this.id) clearTimeout(this.id)
      this.id = setTimeout(() => {
        if (this.text === barcodeValue) {
          this.text = ""
        }
      }, 5000);
    },
    onLoaded() {
      console.log("barcode scanner loaded");
    }
  },
  computed: {
    isLoggedIn() {
      let currentUser = firebase.auth().currentUser
      console.log(currentUser)
      return ( !currentUser ) ? false : true
    }
  }
}
</script>
