<template>
  <div>
    <RecordCreate v-if="isLoggedIn" />
    <RecordList />
  </div>
</template>
<script>
import firebase from 'firebase'
// import Home from '@/views/Home.vue'
import RecordCreate from '@/components/RecordCreate.vue'
import RecordList from '@/components/RecordList.vue'

const authListener = firebase.auth().onAuthStateChanged(function(user) {
  console.log(user, 'user')
  if (user){
    this.isAuthed = true;
  }
})

export default {
  components: {
    RecordCreate,
    RecordList
  },
  data() {
    return {
      isAuthed: false
    }
  },
  setup() {
    authListener()
  },
  computed: {
    isLoggedIn() {
      let currentUser = firebase.auth().currentUser
      return ( !currentUser ) ? false : true
    }
  }
}
</script>
