<template>
  <div class="card card-body mt-4">
    <form @submit.prevent="onSubmit">
      <div class="form-group">
        <label>Discogs URL</label>
        <input v-model="form.discogsURL" class="form-control" required />
      </div>
      <button type="submit" class="btn btn-success mt-3">
        Create Record
      </button>
    </form>
  </div>
</template>

<script>
import { createRecord } from '@/firebase'
import { reactive } from 'vue'
import { findReleaseMatch } from '@/services/DiscogsService'

export default {
  setup() {
    const form = reactive({ discogsURL: '' })
    const onSubmit = async () => {
      let response = await findReleaseMatch( form.discogsURL )
      await createRecord({ ...response })
      // await addReleaseToCollection(response.id)
      form.discogsURL = ''
    }
    return { form, onSubmit }
  }
}
</script>
